/**
 * wInview: a plugin for observe if an element is in viewport
 *
 * Usage:
 *   - Observe when a element reach top/bottom of viewport, similar to `new Waypoint(option)`
 *       observe({
 *          element: <HTMLElement | jQuery>,
 *          handler: <Function>,
 *          offset: <string | number | 'bottom-in-view' | Function>,
 *       });
 *
 *  FIXME: offset 如果 >= 100% 不會動
 *
 *   - Observe when a element enter/entered/exit/exited viewport, similar to `new Waypoint.Inview(option)`
 *       observe({
 *          element: <HTMLElement | jQuery>,
 *          enter: <Function>,
 *          entered: <Function>,
 *          exit: <Function>,
 *          exited: <Function>,
 *       });
 *     Warning: `offset` is not expected to be set here, it might cause errors
 *
 *   - wWaypoint(option) and wWaypoint.Inview(option) is also avaliable
 *
 * Notes:
 *   - Known bug: when scroll quite quickly (e.g. press `PageDown`, `End`), some events might miss
 *   - Warning: event transaction is different from Waypoint, this does NOT always start from `enter` (maybe `entered`)
 */
var wInview = {
  isSupported: 'IntersectionObserver' in window,
  direction: 'down',
  _options: [],

  _init: function() {
    // use polyfill for clients don't have intersection observer
    if (!('IntersectionObserver' in window)) return this._initPolyfill();

    var me = this;
    var lastScrollY = 0;
    window.addEventListener('scroll', function () {
      me.direction = window.scrollY > lastScrollY ? 'down' : 'up';
      lastScrollY = window.scrollY;
    });
    window.addEventListener('resize', function () {
      me._rebuildAllObserver();
    });
  },

  _initPolyfill: function() {
    var me = this;
    var lastScrollY = 0;
    me._createInstance = me._createInstancePolyfill;

    window.addEventListener('scroll', function () {
      me.direction = window.scrollY > lastScrollY ? 'down' : 'up';
      lastScrollY = window.scrollY;

      me._options.forEach(function(option) {
        if (!option.instance) return;
        option.instance.dispatch();
      });
    });
    window.addEventListener('resize', function () {
      me._rebuildAllObserver();
    });
  },

  observe: function(option) {
    var me = this;
    option = me._handlerTranslate(option);

    if (!option.element) {
      if (console.error) console.error('wWaypoint: element is not available');
      return;
    }

    // break jQuery into native HTMLElement and redo observe
    if (option.element instanceof jQuery) {
      return option.element.map(function(_, element) {
        var option_ = $.extend({}, option);
        option_.element = element;
        return me.observe(option_);
      }).get();
    }

    me._options.push(option);
    option.instance = me._createInstance(option);
    return option.instance;
  },

  _handlerTranslate: function(option) {
    if (!option.handler) return option;
    var handler = option.handler;

    if (option.offset === 'bottom-in-view') {
      option.entered = function(direction) {
        if (direction === 'down') handler.bind(this)(direction);
      };
      option.exit = function(direction) {
        if (direction === 'up') handler.bind(this)(direction);
      };
      delete option.handler;
      option.offset = 0;

      return option;
    }

    option.entered = function(direction) {
      if (direction === 'up') handler.bind(this)(direction);
    };
    option.exit = function(direction) {
      if (direction === 'down') handler.bind(this)(direction);
    };
    delete option.handler;

    return option;
  },

  _createInstance: function(option) {
    var me = this;

    var calc = {
      elementReact: option.element.getBoundingClientRect(),
      offsetTop: me._computeOffsetTop(option)
    };
    calc.outOfViewWidth = Math.max(0, calc.elementReact.right - document.body.clientWidth) - Math.min(0, calc.elementReact.left) + 1;
    calc.viewport = Math.max(0, window.innerHeight - calc.offsetTop);
    calc.yThreshold = Math.min(1, calc.viewport / calc.elementReact.height);
    calc.xThreshold = Math.max(0, 1 - calc.outOfViewWidth / calc.elementReact.width);
    calc.threshold = calc.yThreshold * calc.xThreshold;
    calc.largerThanViewport = calc.threshold < calc.xThreshold;
    var wasIntersecting;

    if (calc.threshold > 1) calc.threshold = 1; // for invisible element and some weird condition

    var interObsOptions = {
      rootMargin: -calc.offsetTop + 'px 0px 0px 0px',
      threshold: [0, calc.threshold],
    };

    if (!calc.elementReact.height || !calc.elementReact.width || calc.threshold < 0 || (typeof calc.threshold != 'number')) {
      if (window.console && window.JSON && JSON.stringify) {
        console.log(JSON.stringify({
          'interObsOptions': interObsOptions,
          'element': option.element.outerHTML.substr(0,150),
          'calc': calc
        }));
      }
    }

    var observer = new IntersectionObserver(function(entries) {
      var entry = entries[0];
      var event = null;

      // FIXME: if scroll quite quickly, it might miss some event, e.g. (none -> entered -> exited)
      if (!wasIntersecting && entry.isIntersecting) {
        event = 'enter';
      }
      // wasIntersecting maybe undefined if in view at beginning
      if (wasIntersecting !== false && entry.isIntersecting && entry.intersectionRatio >= calc.threshold) {
        // if target is larger than viewport, part of that is exiting viewport but some part not entered yet
        if (calc.largerThanViewport) event = 'exit';
        // if target is smaller than viewport, all of that entered
        else event = 'entered';
      }
      if (wasIntersecting && entry.isIntersecting && entry.intersectionRatio < calc.threshold) {
        // if target is larger than viewport, all of that entered and some already exited
        if (calc.largerThanViewport) event = 'entered';
        // if target is smaller than viewport, part of that is exiting viewport
        else event = 'exit';
      }
      if (wasIntersecting && !entry.isIntersecting) {
        event = 'exited';
      }

      if (option[event] && event !== observer.lastEvent && observer.enabled) option[event].bind(observer)(me.direction);
      wasIntersecting = entry.isIntersecting;
      observer.lastEvent = event;
    }, interObsOptions);

    // polyfill of waypoints handler destroy
    observer.destroy = function () {
      observer.unobserve(option.element);
    };
    observer.disable = function () {
      observer.enabled = false;
    };
    observer.enable = function () {
      observer.enabled = true;
    };
    observer.element = option.element;
    observer.enabled = true;
    observer.lastEvent = null;

    observer.observe(option.element);
    return observer;
  },

  _createInstancePolyfill: function(option) {
    var me = this;
    var offset = me._computeOffsetTop(option);
    var initialBoundingReact = option.element.getBoundingClientRect();
    var largerThanViewport = initialBoundingReact.height > window.innerHeight - offset;
    var windowHeight = window.innerHeight;
    var wasIntersecting;
    var wasMaxIntersecting = false;

    function getEvent(boundingReact) {
      var isIntersecting = boundingReact.top <= windowHeight && boundingReact.bottom >= offset;
      var isMaxIntersecting = largerThanViewport ?
        boundingReact.top <= offset && boundingReact.bottom >= windowHeight :
        boundingReact.top >= offset && boundingReact.bottom <= windowHeight ;
      var event;

      if (!wasIntersecting && isIntersecting) event = 'enter';

      // wasIntersecting maybe undefined if in view at beginning
      if (wasIntersecting !== false && isIntersecting && isMaxIntersecting) {
        // if target is larger than viewport, part of that is exiting viewport but some part not entered yet
        if (largerThanViewport) event = 'exit';
        // if target is smaller than viewport, all of that entered
        else event = 'entered';
      }
      if (wasIntersecting && isIntersecting && wasMaxIntersecting && !isMaxIntersecting) {
        // if target is larger than viewport, all of that entered and some already exited
        if (largerThanViewport) event = 'entered';
        // if target is smaller than viewport, part of that is exiting viewport
        else event = 'exit';
      }

      if (wasIntersecting && !isIntersecting) event = 'exited';

      wasIntersecting = isIntersecting;
      wasMaxIntersecting = isMaxIntersecting;
      return event;
    }

    var instance = {
      enabled: true,
      lastEvent: null,
      element: option.element,
      dispatch: function() {
        var boundingReact = option.element.getBoundingClientRect();
        var event = getEvent(boundingReact, this.lastEvent);

        if (option[event] && event !== this.lastEvent && this.enabled) option[event].bind(this)(me.direction);
        this.lastEvent = event;
      },
      destroy: function() {
        this.dispatch = function(){};
      },
      enable: function() {
        this.enabled = true;
      },
      disable: function() {
        this.enabled = false;
      }
    };

    instance.dispatch();
    return instance;
  },

  _computeOffsetTop: function(option) {
    if (!option.offset) return 0;

    if (typeof option.offset === 'function') return option.offset();

    if (!isNaN(option.offset)) {
      return parseFloat(option.offset);
    }

    if (/%$/.exec(option.offset)) {
      return window.innerHeight * parseFloat(option.offset) / 100;
    }
  },

  _rebuildAllObserver: function() {
    var me = this;
    me._options.forEach(function(option) {
      var lastEvent = null;
      if (option.instance) {
        lastEvent = option.instance.lastEvent;
        option.instance.destroy();
      }

      option.instance = me._createInstance(option);
      option.instance.lastEvent = lastEvent;
    });
  }
};
wInview._init();

export default wInview;
window.wInview = wInview;
window.wWaypoint = function() {
  return wInview.observe.apply(wInview, arguments);
};
window.wWaypoint.Inview = window.wWaypoint;
