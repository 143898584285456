var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "<a target=\"_blank\" class=\"article-block wnad-article\" href=\""
    + alias3(alias2((depth0 != null ? depth0.url : depth0), depth0))
    + "\" title=\""
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "\" data-id=\""
    + alias3(alias2((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n  <article>\n    <span class=\"image\" style=\"background-image: url('https://womany.net/cdn-cgi/image/w=600,fit=scale-down,f=auto/"
    + alias3(alias2((depth0 != null ? depth0.image : depth0), depth0))
    + "');\"></span>\n    <span class=\"context\">\n      <h2>"
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "</h2>\n      <span class=\"author\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.info : depth0)) != null ? stack1.author_name : stack1), depth0))
    + "</span>\n      <span class=\"sponsor\">贊助</span>\n    </span>\n  </article>\n</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.units : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});