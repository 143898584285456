var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<a target=\"_blank\" href=\""
    + alias5(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias5(((helper = (helper = helpers.item_id || (depth0 != null ? depth0.item_id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"item_id","hash":{},"data":data}) : helper)))
    + "\">\n  <img src=\"https://womany.net/cdn-cgi/image/"
    + alias5(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"size","hash":{},"data":data}) : helper)))
    + "f=auto/"
    + alias5(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\"\">\n</a>\n";
},"useData":true});