// https://github.com/rmm5t/jquery-timeago/tree/master/locales
import './jquery.timeago';

switch(navigator.language) {
  case "zh-TW":
    jQuery.timeago.settings.strings = {
      prefixAgo: null,
      prefixFromNow: "從現在開始",
      suffixAgo: "前",
      suffixFromNow: null,
      seconds: "不到 1 分鐘",
      minute: "1 分鐘",
      minutes: "%d 分鐘",
      hour: "1 小時",
      hours: "%d 小時",
      day: "1 天",
      days: "%d 天",
      month: "1 個月",
      months: "%d 個月",
      year: "1 年",
      years: "%d 年",
      numbers: [],
      wordSeparator: ""
    };
    break;
  case "zh-CN":
    jQuery.timeago.settings.strings = {
      prefixAgo: null,
      prefixFromNow: "从现在开始",
      suffixAgo: "前",
      suffixFromNow: null,
      seconds: "不到 1 分钟",
      minute: "1 分钟",
      minutes: "%d 分钟",
      hour: "1 小时",
      hours: "%d 小时",
      day: "1 天",
      days: "%d 天",
      month: "1 个月",
      months: "%d 月",
      year: "1 年",
      years: "%d 年",
      numbers: [],
      wordSeparator: ""
    };
    break;
  default:
    // Default is English
}
