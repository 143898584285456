import '../shared/js_polyfill';
import '../shared/wWaypoint';
import '../vendors/slick';
import '../vendors/jquery.timeago.locale-womany';
import '../plugin/jquery.wNad';
import '../plugin/jquery.wDfp';
import '../vendors/jquery.dfp.min';
import '../vendors/masonry.pkgd.min';
// import docCookies from '../vendors/docCookies';
import tippy from 'tippy.js';

window.widgetTippy = tippy;
window.Handlebars = require('handlebars');

var wWidgets = ({
  _$mainCtnt: null,

  _init: function() {
    var me = this;
    me._$mainCtnt = $('#w-main-content');

    me._trackingClick();
    var pageType = wHome.page || 'womany'; //womany | gender | family | hk | lianhonghong | page_id

    me._renderingWidgets(pageType);
  },

  _adjustLayout: function() {
    if (this._$mainCtnt.data('masonry')) this._$mainCtnt.masonry('reloadItems');
    this._$mainCtnt.masonry({
      itemSelector: '.block',
      columnWidth: 40
    });
  },

  _renderingWidgets: function(pageType) {
    var me = this;
    var _$mainCtnt = me._$mainCtnt;
    var $body = $('body');

    me._prepareWigDat(pageType).done(function(wigDat) {
      // console.log('prepare widget done, wigdat', wigDat)

      wigDat.order = (function(wigOrderDat) {
        for (var size in wigOrderDat) {
          wigOrderDat[size] = wigOrderDat[size].map(function(sid){
            if (!sid.includes('(')) return sid;
            var id_key = sid.match(/(\d+)\s\((\S+)\)/);
            return id_key[1] + '-' + id_key[2];
          });
        }
        return wigOrderDat;
      })(wigDat.order);

      var widgetOrder = (function(){
        var mainCtntW = _$mainCtnt.width();
        if (mainCtntW >= 40 * 32)
          return wigDat.order.max;   // content == 1280 (screen > 1500)
        if (mainCtntW >= 40 * 24)
          return wigDat.order.mid;   // screen width 960~1280
        if (mainCtntW >= 40 * 16)
          return wigDat.order.min;   // screen width 640~960
        return wigDat.order.mob;     // screen width < 640
      })();

      widgetOrder = widgetOrder.reverse();

      var availableWidget = Object.keys(wigDat.widgetScripts);

      widgetOrder = widgetOrder.filter(function(wg) {
        var avail = availableWidget.includes(wg);
        if (!avail && window.console) console.log('Error: widget ' + wg + ' not found');
        return avail;
      });

      var currentOrder = [];
      var wgNums = widgetOrder.length;

      widgetOrder.forEach(function(id) {
        var wg = wigDat.widgetScripts[id];

        $.when(wg.initData()).done(function() {
          var dat = this || null; //template dat returned by initDone()

          //render widget html
          var $block = $(wg.outer_html_raw());
          var handlebar_string;

          if (dat == 'fail') {
            handlebar_string = Handlebars.partials['widget/' + wg.name]();
            $block.addClass('initfail');
          }
          else {
            handlebar_string = Handlebars.partials['widget/' + wg.name](dat);
          }
          $block.find('.container').append(handlebar_string);

          // due to async return of each widget, we need to look for position sequent to insert widget
          var beginSearch = widgetOrder.indexOf(id) + 1;
          var found = false;

          for (var i=beginSearch; i<wgNums; i++) {
            var tgt = widgetOrder[i];

            if (currentOrder.indexOf(tgt) != -1) {
              _$mainCtnt.children('#block' + tgt).after($block);
              found = true;
              break;
            }
          }
          if (!found) _$mainCtnt.prepend($block);
          currentOrder.push(id);

          if (dat != 'fail') wg.afterRender($block);   // do after rendering script of widget

          // If all widget render finished, enable marsonry
          // console.log(currentOrder, widgetOrder);
          if ((currentOrder.length == wgNums) && (_$mainCtnt.width() >= 640)){
            window.setTimeout(function(){
              me._adjustLayout();
            }, 300);
          }
        });
      });

      $body.attr('data-widget-page', pageType);
    });
  },

  _prepareWigDat: function(pageType) {
    var dfd = $.Deferred();

    var wigDat = {
      widgetScripts: {},
      subTemplatesList: null,
      order: {},
      source: {},
      allID: null,
      ready: false
    };

    $.ajax({
      type: 'GET',
      url: womany.api + '/user/pages/' + pageType + '/ordering',
      xhrFields: { withCredentials: true }
    })
    .then(function(res) {
      if (!res || !res.data || !res.data.user || !res.data.user.order || !res.data.user.all_widgets) {
        throw Error('get widget order error');
      }

      wigDat.subTemplatesList = res.data.user.widgets_inner_list;
      wigDat.order = res.data.user.order;

      var allWidgetList = res.data.user.all_widgets;

      if (!Array.isArray(allWidgetList) || !allWidgetList.length) {
        throw Error('widget list data error');
      }
      allWidgetList = allWidgetList.map(function(id) {
        // remove parameter from widget id eg, 105 (womany)
        var index = id.indexOf('(');
        if (index < 0) return id;
        return id.substr(0, index-1);
      });

      wigDat.allID = wUtil.arrayUniq(allWidgetList).sort();
    })
    .then(function() {
      $.getJSON(womany.api + '/widgets', { 'ids': wigDat.allID.join() }).done(function(res) {

        if (!res || !res.data || !res.data.widgets) {
          throw Error('get widget data error');
        }
        // console.log('api.womany.net/widgets', res.data.widgets);

        var widgetCSSs = '';
        var widgetCSSkey = [];

        res.data.widgets.forEach(function(widget) {
          // console.log(index, widget);

          widget.id = '' + widget.id;

          if (widgetCSSkey.indexOf(widget.name) < 0) {
            widgetCSSs += widget.css;
            widgetCSSkey.push(widget.name);
          }

          Handlebars.partials['widget/' + widget.name] = Handlebars.compile(widget.template);
          if (widget.template_inner) Handlebars.partials['widget/' + widget.name + '_inner'] = Handlebars.compile(widget.template_inner);

          widget.key = function(id) {
            var found = id.match(/\d+-(.*)/);
            if (found && found.length)
              return found[1];
          }(widget.id) || '';

          widget.outer_html_raw_raw = function(widget) {
            var fullcontent = '';
            var appendH2 = '';
            var divBgColorStyle = '';

            if (widget.is_fullcontent) fullcontent = ' data-fullcontent="true"';
            if (widget.background_color) divBgColorStyle = "background-color: " + widget.background_color;

            if (widget.title) {
              if (widget.is_title_highlight)
                appendH2 = '<h2 class="title highlight"><span>' + widget.title + '</span></h2>';
              else
                appendH2 = '<h2 class="title" style="' + divBgColorStyle + '"><span>' + widget.title + "</span></h2>";
            }

            return '<section class="block" id="block' + widget.id + '"' + fullcontent +
              ' data-name="' + widget.name + '"' +
              ' data-gridwidth="' + widget.width + '" data-gridheight="' + widget.height + '"' +
              ' data-gridmobwidth="' + widget.mobile_width + '" data-gridmobheight="' + widget.mobile_height + '">' +
                appendH2 +
                '<div class="widget_inner" style="' + divBgColorStyle + '">' +
                  '<div class="container" data-widgetkey="' + widget.key + '"></div>' +
                '</div>' +
            '</section>';
          }(widget);

          // console.log('widget.outer_html_raw_raw', widget.outer_html_raw_raw)

          // widgetVar is provided to be use by block1_js & block2_js
          var widgetVar = (widget.key)? {
            'key': widget.key,
            'dat': function() {
              var keyStr = widget.vars[widget.key];
              if (keyStr.indexOf('{') >= 0)
                keyStr = JSON.parse(keyStr);
              else
                keyStr = keyStr.replace(/['"]/g, '');
              return keyStr;
            }()
          } : null;
          // console.log('widgetVar', widgetVar);

          var funcInitData = function() {
            /*
              Block #1, the block below is where we let user to add their codes
              After we prepared data for template, we'll need to call initDone() and passed data obj to start DOM rendering.
                initDone(dataObj);
              or if fetch data failed, we can call initFail to prevent widget for show (it will still rendering but won't call afterRender)
                initFail(); return;
            */

            var dfd = new $.Deferred();

            var initDone = dfd.resolveWith;
            var initFail = function() { dfd.resolveWith('fail'); };

            try {
              eval(widget.block1_js);
            }
            catch (e){
              if (window.console) console.error('Error: widget ' + widget.id + ' initData, ' + e);
              initFail();
            }

            return dfd.promise();
          };

          var funcAfterRender = function($block) {
            /*
              Block #2, where user is able to react to the events inside $block
              After DOM rendering, we'll automatically call afterRender() for you to start further interaction.
              You can use wigTemplate(dat) to reuqest HTML inner template.
            */

            var me = this;
            var wigTemplate = null;
            var widgetDone = function() {
              if ($(window).width() >= 640) wWidgets._adjustLayout();
            };
            if (wigDat.subTemplatesList.indexOf(widget.name) > -1)
              wigTemplate = Handlebars.partials['widget/' + widget.name + '_inner'];

            try {
              eval(widget.block2_js);
            }
            catch (e){
              if (window.console) console.error('Error: widget ' + me.id + ' afterRender, ' + e);
            }

            var $imgs = $block.find('img');
            var imgLen = $imgs.length;
            if (imgLen) $imgs.on('load', function(){
              imgLen--;
              if (imgLen == 0) widgetDone();
            });
          };

          var widgetScripts_data = {
            'id': widget.id,
            'name': widget.name,
            'outer_html_raw': function(){
              return widget.outer_html_raw_raw;
            },
            'initData': funcInitData,
            'afterRender': funcAfterRender
          };

          wigDat.widgetScripts[widget.id] = widgetScripts_data;
        });

        var style = document.createElement('style');
        style.innerHTML = widgetCSSs;
        document.head.appendChild(style);

        // console.log('wigDat', wigDat.widgetScripts)
        dfd.resolve(wigDat);
      });
    })
    .catch(function(e) {
      if (window.console) console.error('Error: widget prepare error, ' + e);
    });

    return dfd.promise();
  },

  _trackingClick: function() {
    var me = this;
    me._$mainCtnt.on('click', 'a', function(){
      var $link = $(this);
      var url = $link.attr('href');
      if (url == 'javascript:void(0);') return;

      var widget = $link.parents('.block')[0];
      var wgName = widget.id + '_' + widget.dataset.name;

      if (window.ga) ga('send', 'event', 'website-tracking', wgName, 'openlink_' + url);
    });
  }
  /*
  switchSite: function(subsite) {
    this._$mainCtnt.empty();
    this._renderingWidgets(subsite);

    var subsiteMeta = {
      womany: ['/', '女人迷 Womany'],
      gender: ['/genderpower', '性別力 Gender Power'],
      family: ['/imhome', '回家吧 I’m home'],
      hk:     ['/hk', 'Womany HK 女人迷 香港']
    };
    var url = subsiteMeta[subsite][0];
    var title = subsiteMeta[subsite][1];

    document.title = title;
    history.pushState(null, title, url);

    $('link[data-subsite]').prop('disabled', true)
                          .filter('[data-subsite='+ subsite +']')
                          .prop('disabled', false);

    document.body.classList.remove('s-womany', 's-gender', 's-family');
    document.body.classList.add('s-' + subsite);

    $.ajax(womany.api + '/home/pv_record?subsite=' + subsite, { xhrFields: { withCredentials: true } });

    if (window.ga) {
      ga('send', 'event', 'website-tracking', 'home-switch-subsite', subsite);
      // ga('send', 'pageview', { 'page': url, 'title': title });
    }
    if (window.mixpanel) mixpanel.track_pageview(); // FIXME: ensure url & title is corrent
  }
  */
});

wWidgets._init();
window.wWidgets = wWidgets;
