var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "      <li><i class=\"far fa-angle-right\"></i><a href=\""
    + alias3(alias2((depth0 != null ? depth0.url : depth0), depth0))
    + "\" data-id=\""
    + alias3(alias2((depth0 != null ? depth0.item_id : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"article-see-also sidewig\">\n  <div class=\"header\">\n    <span class=\"title\">迷人話題</span>\n  </div>\n\n  <ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.units : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </ul>\n</div>\n";
},"useData":true});