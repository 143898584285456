/*
 * Dymanic get and insert dfp based on https://support.google.com/dfp_premium/answer/1638620
 *
 *   <span/div class='dfp' data-unit='womany_article_620x80_lower' data-size='620x80' data-min-width='620'></span>
 *   <script> $('.dfp').wDfp(options); </script>
 *
 * attrib:
 *   data-unit: dfp unit
 *   data-size: dfp size
 *   data-type: image/iframe, default to image
 *   data-min-width (optional): apply when view port larger then min-width
 *   data-max-width (optional): apply when view port smaller then max-width
 *
 * options:
 *   rndNum: Random integer, Correlator/cache-busting paramete
 *   serialNum: Unique integer counting up
 *
 */

(function($) {
  $.fn.wDfp = function(options) {
    var set = $.extend({
      rndNum: function(){ return Math.floor(Math.random() * (999999)); }(),
      serialNum: 0
    }, options || {});

    set.vpWidth = $(window).width();

    return this.each(function(){
      var $dfp = $(this);

      var unit = $dfp.attr('data-unit') || null;
      var size = $dfp.attr('data-size') || null;
      var type = $dfp.attr('data-type') || 'image'; // image | iframe
      if (!unit || !size) return false;

      var minWidth = $dfp.attr('data-min-width') * 1 || null;
      var maxWidth = $dfp.attr('data-max-width') * 1 || null;

      if (minWidth && (set.vpWidth < minWidth)) return this;
      if (maxWidth && (set.vpWidth > maxWidth)) return this;

      var dat = {
        'unit': unit,
        'size': size,
        'rndNum': set.rndNum,
        'serialNum': set.serialNum++
      };

      if (type == 'iframe'){
        $dfp
          .append(require('../../templates/dfp/wdfp-iframe')(dat))
          .addClass('dfp-' + unit).addClass('dfp_iframe');
      }
      else {
        $dfp
          .append(require('../../templates/dfp/wdfp')(dat))
          .addClass('dfp-' + unit)
          .find('img').on('load', function(){
            if (this.height <= 1) $dfp.addClass('dfp_1x1');
          });
      };
    });
  };
})(jQuery);
