var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<a target=\"_blank\" href=\"https://securepubads.g.doubleclick.net/gampad/jump?iu=/120480087/"
    + alias5(((helper = (helper = helpers.unit || (depth0 != null ? depth0.unit : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"unit","hash":{},"data":data}) : helper)))
    + "&sz="
    + alias5(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"size","hash":{},"data":data}) : helper)))
    + "&c="
    + alias5(((helper = (helper = helpers.rndNum || (depth0 != null ? depth0.rndNum : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"rndNum","hash":{},"data":data}) : helper)))
    + "&tile="
    + alias5(((helper = (helper = helpers.serialNum || (depth0 != null ? depth0.serialNum : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"serialNum","hash":{},"data":data}) : helper)))
    + "\">\n  <img src=\"https://securepubads.g.doubleclick.net/gampad/ad?iu=/120480087/"
    + alias5(((helper = (helper = helpers.unit || (depth0 != null ? depth0.unit : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"unit","hash":{},"data":data}) : helper)))
    + "&sz="
    + alias5(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"size","hash":{},"data":data}) : helper)))
    + "&c="
    + alias5(((helper = (helper = helpers.rndNum || (depth0 != null ? depth0.rndNum : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"rndNum","hash":{},"data":data}) : helper)))
    + "&tile="
    + alias5(((helper = (helper = helpers.serialNum || (depth0 != null ? depth0.serialNum : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"serialNum","hash":{},"data":data}) : helper)))
    + "\">\n</a>\n";
},"useData":true});