import '../shared/wWaypoint';

/*
 * Dymanic get and insert native ad
 *
 *   <span/div class='wnad' data-unit='6629c3685594cb80' data-size='300x250' data-max-width='600' data-min-width='300'></span>
 *   <script> $('.wnad').wNad(options); </script>
 *   <script> $('.wnad').wNad(options).done(function(){ console.log(this) }); </script>
 *
 * attrib:
 *   data-unit: native ad unit code
 *   data-size: image size in WxH, resize image to fit this size
 *   data-min-width (optional): apply when view port larger then min-width
 *   data-max-width (optional): apply when view port smaller then max-width
 *   data-ref (optional): ref string to appending to link
 *   data-template: (optional): name of hbs template, if no template then will render as typical banner
 *   data-num: (optional): how many ads get rendered (in template mode), default to 1
 *   data-replace: (optional): used within template mode, if true, template will fully replace wnad dom element
 *
 */

(function($) {
  $.fn.wNad = function() {

    var dfd = $.Deferred();

    var vpWidth = $(window).width();
    var innerDfdList = [];

    var returnObj = this.each(function(){
      var $nad = $(this);

      var inDfd = $.Deferred();

      var unit = $nad.attr('data-unit') || null;
      var hadLoaded = ($nad.attr('data-wnad-stat') == 'loaded');
      if (!unit || hadLoaded) {
        inDfd.resolve();
      }
      else {
        $nad.attr('data-wnad-stat', 'loaded');
        var ref = $nad.attr('data-ref') || null;
        var template = $nad.attr('data-template') || null;
        var unitNum = $nad.attr('data-num') || 1;
        unitNum *= 1;

        var minWidth = $nad.attr('data-min-width') * 1 || null;
        var maxWidth = $nad.attr('data-max-width') * 1 || null;

        if (minWidth && (vpWidth < minWidth)) return this; // fixme: add 1x1 or other class
        if (maxWidth && (vpWidth > maxWidth)) return this;

        var doReplace = $nad.attr('data-replace') || false;

        var size = $nad.attr('data-size') || null;
        var sizeW = null;
        var sizeH = null;
        if (size) {
          var sizeArry = size.split('x');
          if (sizeArry.length == 2) {
            sizeW = sizeArry[0];
            sizeH = sizeArry[1];
          }
        }

        $.getJSON(womany.api + '/nads/' + unit).always(function (adDat, resStatus) {
          if (!(resStatus == "success" && adDat.items && Array.isArray(adDat.items) && adDat.items.length > 0)){
            $nad.addClass('nad_1x1'); // fixme: remove "nad_", need to adjust all css including this class
            inDfd.resolve();
          }
          else {
            // shuffle to show random ads
            adDat.items = wUtil.arrayShuffle(adDat.items);

            var adUnit = [];
            for (var i = 0; i < unitNum; i++)
              if (adDat.items.length)
                adUnit.push(adDat.items.pop());

            // adUnit = [{
            //   id: null
            //   image: "https://castle.womany.net/images/ad_items/203/womany_imgad_1512027051-13390-6821.gif"
            //   item_id: 203
            //   name: ""
            //   sponsor: null
            //   type: null
            //   url: "/redirects/203"
            //   size: "w=256,h=256,fit=scale-down,"
            // }]

            var resizeOpt = '';
            if (sizeW) resizeOpt = 'w=' + sizeW + ',';
            if (sizeH) resizeOpt = resizeOpt + 'h=' + sizeH + ',';
            if (resizeOpt) resizeOpt += 'fit=scale-down,';

            adUnit.forEach(function(ad, i) {
              if (ref) {
                // add ref str to url
                ad.url = ad.url + '?ref=' + ref;
                if (adUnit.length > 1) ad.url = ad.url + '-' + (i + 1);
              }
              ad.size = resizeOpt;
            });

            var $unit = (template) ?
              $(require('../../templates/nad/' + template)({'units': adUnit})) :
              $(require('../../templates/nad/banner')(adUnit[0]));

            if (doReplace)
              $nad.replaceWith($unit.attr('data-wnad-stat', 'loaded'));
            else
              $nad.append($unit);

            // impression
            var inview = wWaypoint({
              element: $unit[0],
              handler: function () {
                var impUnits = [];
                adUnit.forEach(function(ad){
                  impUnits.push(ad.item_id);
                  // if (window.ga) ga('send', 'event', 'website-tracking', 'native-ad', 'imps-' + ad.item_id, {
                  //   nonInteraction: true
                  // });
                });
                $.post(womany.api + '/nads/imps/' + impUnits.join());
                this.destroy();
              },
              offset: 'bottom-in-view'
            });

            inDfd.resolveWith($nad);
          }
        });
      }

      innerDfdList.push(inDfd.promise());
    });

    $.when.apply($, innerDfdList).always(function() {
      dfd.resolveWith(returnObj);
    });

    return dfd.promise();
  };
})(jQuery);
